import React from "react"
import { css } from "styled-components"
import { ParallaxLayer } from "react-spring/renderprops-addons"

const Content = React.forwardRef((props, ref) => {
  const { speed, offset, children, factor } = props
  return (
    <ParallaxLayer
      speed={speed}
      offset={offset}
      factor={factor || 1}
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 50;
      `}
    >
      {children}
    </ParallaxLayer>
  )
})

export default Content

import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"
import starsDark from "../../images/stars-dark.svg"
import { UpDown, UpDownWide } from "../../utils/animations"
import SVG from "../svg"

const Background = () => {
  return (
    <>
      <ParallaxLayer
        offset={0}
        speed={0}
        factor={20}
        style={{
          backgroundImage: `url(${starsDark})`,
          backgroundSize: "auto",
          backgroundRepeat: "repeat",
        }}
      />
      <ParallaxLayer offset={4} speed={-1.2}>
        <UpDownWide>
          <SVG icon="asteroid" width={130} left="80%" top="20%" />
        </UpDownWide>
      </ParallaxLayer>
      <ParallaxLayer offset={6} speed={1.3}>
        <UpDown>
          <SVG icon="asteroid" width={80} left="60%" top="70%" />
        </UpDown>
      </ParallaxLayer>
    </>
  )
}

export default Background

import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons"
import { css } from "styled-components"
import Content from "../elements/content"
import Inner from "../elements/inner"
import Header from "../components/Header"
import Background from "../components/Background"
import blackHoleImage from "../images/black-hole-svgrepo-com.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Introuvable" />
    <Parallax pages={1}>
      <ParallaxLayer offset={0} factor={1}>
        <div
          css={css`
            position: relative;
            margin: 0 auto;
            height: 100%;
            width: auto;
          `}
        >
          <img
            srcSet={blackHoleImage}
            alt="black hole in background"
            css={css`
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 0.4;
              width: auto;
              height: 100%;
            `}
          />
        </div>
      </ParallaxLayer>
      <Content>
        <Background />
        <ParallaxLayer offset={0}>
          <Header />
        </ParallaxLayer>

        <Inner>
          <h1
            css={css`
              text-align: center;
            `}
          >
            Page portée disparue
          </h1>
          <p
            css={css`
              text-align: center;
            `}
          >
            La page que vous recherchez a été engloutie dans un trou noir. Merci
            de regagner la page d'accueil en toute sécurité. Les trous noirs
            sont dangereuuuux !
          </p>
        </Inner>
      </Content>
    </Parallax>
  </Layout>
)

export default NotFoundPage

import React from "react"
import { css } from "styled-components"

const Inner = ({ children }) => {
  return (
    <div
      css={css`
        text-align: left;
        color: inherit;
        width: 90%;
      `}
    >
      {children}
    </div>
  )
}

export default Inner
